<template>
  <div class="outBox">
    <!-- 大图部分 -->
    <BigBgimg :list="bigData"></BigBgimg>
    <!-- 寻找合作伙伴 -->
    <div class="partner">
      <Mytitle
        :data="{
          h: '寻找全球合作伙伴',
          p: '我们面向全球寻找一同追求梦想追求卓越的合作伙伴，欢迎诚意的合作方式，拥抱共赢的合作目标，让幻想不止于此!',
        }"
      ></Mytitle>
      <div class="par_conter">
        <div class="item_div">
          <img
            src="@/assets/home/cooper/icon_hezuo_jishuzhichi@2x.png"
            alt=""
          />
          <h3>技术支持</h3>
          <p>
            我们提供以实时美颜滤镜SDK为主 体的技术和相关技术支撑，让您的
            各类产品应用轻松接入我们的SDK
          </p>
        </div>
        <div class="item_div">
          <img
            src="@/assets/home/cooper/icon_hezuo_shangwuhuhui@2x.png"
            alt=""
          />
          <h3>商务互惠</h3>
          <p>
            我们的SDK产品友好支持云平台推 流，欢迎各类云平台联系我们，共同
            进行商业合作推广，实现互惠共赢
          </p>
        </div>
        <div class="item_div">
          <img src="@/assets/home/cooper/icon_hezuo_dingzhi@2x.png" alt="" />
          <h3>定制服务</h3>
          <p>
            我们在现有SDK的基础之上提供定制 功能开发服务，满足您特殊场景的需
            求，让您的产品独一无二
          </p>
        </div>
        <div class="item_div">
          <img src="@/assets/home/cooper/icon_hezuo_qita@2x.png" alt="" />
          <h3>其他技术</h3>
          <p>
            我们是科技创新型的企业，热情拥 抱创新的商业模式，欢迎更好更新
            的合作模式，期待您的参与
          </p>
        </div>
      </div>
    </div>
    <!-- 合作流程 -->
    <div class="flow">
      <Mytitle
        :data="{
          h: '合作流程',
        }"
      ></Mytitle>

      <div class="flow_conter">
        <div class="item">
          <img src="@/assets/home/cooper/step_1@2x.png" alt="" />
          <h3>演示DEMO/获取报价</h3>
        </div>
        <ul class="flex_ul">
          <li v-for="item in 7" :key="item">
            <img src="@/assets/home/cooper/icon_shangwu_liucheng@2x.png" />
          </li>
        </ul>
        <div class="item">
          <img src="@/assets/home/cooper/step_2@2x.png" alt="" />
          <h3>需求分析</h3>
        </div>
        <ul class="flex_ul">
          <li v-for="item in 7" :key="item">
            <img src="@/assets/home/cooper/icon_shangwu_liucheng@2x.png" />
          </li>
        </ul>
        <div class="item">
          <img src="@/assets/home/cooper/step_3@2x.png" alt="" />
          <h3>技术对接</h3>
        </div>
      </div>
    </div>
    <!-- 合作案例 -->
    <div class="case">
      <Mytitle
        :data="{
          h: '合作案例',
        }"
      ></Mytitle>

      <div class="case_conter">
        <div class="case_div">
          <img src="@/assets/home/cooper/商务合作_云平台推流.png" alt="" />
          <span class="span">云平台推流</span>
          <div class="show_div">
            <p>
              <span>云平台推流</span>
              我们友好支持腾讯云、七牛云、声网、网易云、
              环信、即构科技、金山云等主流云平台的推流，
              可轻松接入以实现各类直播、短视频、连麦等应
              用功能。对于非主流云平台我们也有相应工程师
              为您的需求提供技术服务。目前，拓幻TiFancy
              美颜SDK已经成功为100多家依托于云平台推流
              的应用提供了SDK技术和服务。
            </p>
          </div>
        </div>
        <div class="case_div">
          <img src="@/assets/home/cooper/商务合作_中视视频通话.png" alt="" />
          <span class="span">中视视频通话</span>
          <div class="show_div">
            <p>
              <span>中视视频通话</span>
              中视视频通话：视频通话为中视集团自研，通过调用Tifancy美颜SDK渲染功能接口，对视频帧处理API接口进行渲染。使用Tifancy美颜SDK中的美颜、大眼瘦脸功能，为场外记者和演播室连线视频进行美颜、大眼、瘦脸渲染。
            </p>
          </div>
        </div>
        <div class="case_div">
          <img
            src="@/assets/home/cooper/商务合作_火猫游戏直播平台.png"
            alt=""
          />
          <span class="span">火猫游戏直播平台</span>
          <div class="show_div">
            <p>
              <span>火猫游戏直播平台</span>
              Tifancy美颜SDK为火猫直播等平台提供美颜、大眼、瘦脸、贴纸、抖音、哈哈镜等功能。Tifancy美颜SDK采用最高效的纹理渲染方法，CPU占用率整体在40%以下。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 申请试用 -->
    <Try></Try>
  </div>
</template>
<script>
import BigBgimg from "@/components/BigBgimg/index"; //大图
import Try from "@/components/Try/index"; //试用
import Mytitle from "@/components/Mytitle/index"; //标题

export default {
  components: { BigBgimg, Try, Mytitle },
  data() {
    return {
      // 大图数据
      bigData: {
        topTitle: "商务合作",
        topText:
          "帮助合作伙伴快速集成美颜功能，共同开阔企业市场，助力企业智能化升级，共赢未来",
        topBtn: "联系我们",
        topBg: require("@/assets/home/cooper/商务合作首图.png"),
      },
      // 显示
      cloShow: true,
      vidShow: true,
      gameShow: true,
    };
  },
};
</script>
<style lang="scss" scoped>
$width: 79.1%;
.outBox {
  width: 100%;

  // 合作伙伴
  .partner {
    margin-bottom: 100px;
    .par_conter {
      width: $width;
      margin: auto;
      display: flex;
      justify-content: space-between;
      .item_div {
        width: 203px;
        text-align: center;
        img {
          width: 60px;
        }
        h3 {
          font-size: 16px;
          font-weight: 400;
          color: #222222;
          line-height: 40px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
        }
      }
    }
  }
  // 合作流程
  .flow {
    margin-bottom: 100px;
    .flow_conter {
      width: $width;
      margin: auto;
      display: flex;
      justify-content: center;
      text-align: center;
      .flex_ul {
        display: flex;
        align-items: center;
        height: 88px;
        margin: 0 97px;
        li:first-child {
          opacity: 0.2;
        }
        li:nth-child(2) {
          opacity: 0.3;
        }
        li:nth-child(3) {
          opacity: 0.45;
        }
        li:nth-child(4) {
          opacity: 0.6;
        }
        li:nth-child(5) {
          opacity: 0.75;
        }
        li:nth-child(6) {
          opacity: 0.9;
        }
      }
      .item {
        width: 16.2%;
        img {
          width: 88px;
          margin-bottom: 33px;
        }

        h3 {
          font-size: 18px;
          font-weight: 400;
          color: #222222;
        }
      }
    }
  }
  // 合作案例
  .case {
    color: #ffffff;
    .case_conter {
      display: flex;
      .case_div {
        width: 33.33%;
        position: relative;
        overflow: hidden;
        box-shadow:inset 0 0 0 1000px rgba($color: #1D1D1D, $alpha: 0.3) ;
        img {
          display: block;
          width: 100%;
          position: relative;
          z-index: -1;
        }
        .span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          font-size: 20px;
          font-weight: 500;
          color: #ffffff;
          line-height: 38px;
        }
        .show_div {
          width: 100%;
          height: 100%;
          position: absolute;
          color: #ffffff;
          top: 100%;
          transition: top 1s;
          background: rgba($color: #5367e2, $alpha: 0.7);
          text-align: center;
          span {
            display: block;
            height: 50px;
            font-size: 20px;
            font-weight: 500;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
        }
      }
      .case_div:hover {
        .span {
          opacity: 0;
        }
        .show_div {
          top: 0;
        }
      }
    }
  }
}
/* 屏幕小于 */
@media screen and (max-width: 1440px) {
  .outBox {
    width: 1440px;
  }
}
</style>