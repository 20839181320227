var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outBox"},[_c('BigBgimg',{attrs:{"list":_vm.bigData}}),_c('div',{staticClass:"partner"},[_c('Mytitle',{attrs:{"data":{
        h: '寻找全球合作伙伴',
        p: '我们面向全球寻找一同追求梦想追求卓越的合作伙伴，欢迎诚意的合作方式，拥抱共赢的合作目标，让幻想不止于此!',
      }}}),_vm._m(0)],1),_c('div',{staticClass:"flow"},[_c('Mytitle',{attrs:{"data":{
        h: '合作流程',
      }}}),_c('div',{staticClass:"flow_conter"},[_vm._m(1),_c('ul',{staticClass:"flex_ul"},_vm._l((7),function(item){return _c('li',{key:item},[_c('img',{attrs:{"src":require("@/assets/home/cooper/icon_shangwu_liucheng@2x.png")}})])}),0),_vm._m(2),_c('ul',{staticClass:"flex_ul"},_vm._l((7),function(item){return _c('li',{key:item},[_c('img',{attrs:{"src":require("@/assets/home/cooper/icon_shangwu_liucheng@2x.png")}})])}),0),_vm._m(3)])],1),_c('div',{staticClass:"case"},[_c('Mytitle',{attrs:{"data":{
        h: '合作案例',
      }}}),_vm._m(4)],1),_c('Try')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"par_conter"},[_c('div',{staticClass:"item_div"},[_c('img',{attrs:{"src":require("@/assets/home/cooper/icon_hezuo_jishuzhichi@2x.png"),"alt":""}}),_c('h3',[_vm._v("技术支持")]),_c('p',[_vm._v(" 我们提供以实时美颜滤镜SDK为主 体的技术和相关技术支撑，让您的 各类产品应用轻松接入我们的SDK ")])]),_c('div',{staticClass:"item_div"},[_c('img',{attrs:{"src":require("@/assets/home/cooper/icon_hezuo_shangwuhuhui@2x.png"),"alt":""}}),_c('h3',[_vm._v("商务互惠")]),_c('p',[_vm._v(" 我们的SDK产品友好支持云平台推 流，欢迎各类云平台联系我们，共同 进行商业合作推广，实现互惠共赢 ")])]),_c('div',{staticClass:"item_div"},[_c('img',{attrs:{"src":require("@/assets/home/cooper/icon_hezuo_dingzhi@2x.png"),"alt":""}}),_c('h3',[_vm._v("定制服务")]),_c('p',[_vm._v(" 我们在现有SDK的基础之上提供定制 功能开发服务，满足您特殊场景的需 求，让您的产品独一无二 ")])]),_c('div',{staticClass:"item_div"},[_c('img',{attrs:{"src":require("@/assets/home/cooper/icon_hezuo_qita@2x.png"),"alt":""}}),_c('h3',[_vm._v("其他技术")]),_c('p',[_vm._v(" 我们是科技创新型的企业，热情拥 抱创新的商业模式，欢迎更好更新 的合作模式，期待您的参与 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/home/cooper/step_1@2x.png"),"alt":""}}),_c('h3',[_vm._v("演示DEMO/获取报价")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/home/cooper/step_2@2x.png"),"alt":""}}),_c('h3',[_vm._v("需求分析")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/home/cooper/step_3@2x.png"),"alt":""}}),_c('h3',[_vm._v("技术对接")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case_conter"},[_c('div',{staticClass:"case_div"},[_c('img',{attrs:{"src":require("@/assets/home/cooper/商务合作_云平台推流.png"),"alt":""}}),_c('span',{staticClass:"span"},[_vm._v("云平台推流")]),_c('div',{staticClass:"show_div"},[_c('p',[_c('span',[_vm._v("云平台推流")]),_vm._v(" 我们友好支持腾讯云、七牛云、声网、网易云、 环信、即构科技、金山云等主流云平台的推流， 可轻松接入以实现各类直播、短视频、连麦等应 用功能。对于非主流云平台我们也有相应工程师 为您的需求提供技术服务。目前，拓幻TiFancy 美颜SDK已经成功为100多家依托于云平台推流 的应用提供了SDK技术和服务。 ")])])]),_c('div',{staticClass:"case_div"},[_c('img',{attrs:{"src":require("@/assets/home/cooper/商务合作_中视视频通话.png"),"alt":""}}),_c('span',{staticClass:"span"},[_vm._v("中视视频通话")]),_c('div',{staticClass:"show_div"},[_c('p',[_c('span',[_vm._v("中视视频通话")]),_vm._v(" 中视视频通话：视频通话为中视集团自研，通过调用Tifancy美颜SDK渲染功能接口，对视频帧处理API接口进行渲染。使用Tifancy美颜SDK中的美颜、大眼瘦脸功能，为场外记者和演播室连线视频进行美颜、大眼、瘦脸渲染。 ")])])]),_c('div',{staticClass:"case_div"},[_c('img',{attrs:{"src":require("@/assets/home/cooper/商务合作_火猫游戏直播平台.png"),"alt":""}}),_c('span',{staticClass:"span"},[_vm._v("火猫游戏直播平台")]),_c('div',{staticClass:"show_div"},[_c('p',[_c('span',[_vm._v("火猫游戏直播平台")]),_vm._v(" Tifancy美颜SDK为火猫直播等平台提供美颜、大眼、瘦脸、贴纸、抖音、哈哈镜等功能。Tifancy美颜SDK采用最高效的纹理渲染方法，CPU占用率整体在40%以下。 ")])])])])
}]

export { render, staticRenderFns }